import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { t } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { WeaponImageBg } from "@/game-eft/components/WeaponImage.jsx";
import { SearchParamsEnum } from "@/game-eft/constants/constants.mjs";
import type { Items, LeaderboardsWeapon } from "@/game-eft/models/graphql.mjs";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import type { Cell, Column } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function HomeStatisticsTile() {
  const {
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const items: Items = readState.eft.items;
  const $leaderboardsWeapons = state.eft.leaderboards.weapons as
    | Array<LeaderboardsWeapon>
    | Error;
  const leaderboardsWeapons = useEvalState($leaderboardsWeapons);
  const sorted = leaderboardsWeapons
    .slice()
    .sort(
      (a, b) =>
        sanitizeNumber(Number(b.avg_kills_per_raid)) -
        sanitizeNumber(Number(a.avg_kills_per_raid)),
    )
    .slice(0, 4);

  const columns: Column[] = [
    {
      display: t("common:weapon", "Weapon"),
      align: "left",
    },
    {
      display: t("eft:stats.killsRaid", "Kills / Raid"),
      align: "center",
    },
    {
      display: t("eft:stats.accuracy", "Accuracy"),
      align: "center",
    },
    {
      display: t("common:stats.hsPercent", "HS%"),
      align: "center",
    },
  ];

  const rows = sorted.map<Cell[]>((weapData) => {
    const weapon = items[weapData.weapon_id];
    const hs = sanitizeNumber(Number(weapData.avg_headshot_accuracy));
    const link = `/eft/stats/weapons?${SearchParamsEnum.WeaponName}=${encodeURIComponent(weapon.longName)}`;

    const row: Cell[] = [
      {
        display: (
          <div className="flex gap-sp-3 align-center weapon-name">
            <WeaponImageBg weapon={weapon} size="small" />
            <span>{weapon.shortName}</span>
          </div>
        ),
        value: weapon.shortName,
        link,
      },
      {
        display: sanitizeNumber(
          Number(weapData.avg_kills_per_raid),
        ).toLocaleString(language, {
          maximumFractionDigits: 2,
        }),
        value: weapData.avg_kills_per_raid,
        link,
      },
      {
        display: formatToPercent(
          language,
          sanitizeNumber(Number(weapData.avg_accuracy)),
          { min: 1 },
        ),
        value: weapData.avg_accuracy,
        link,
      },
      {
        display: formatToPercent(language, hs > 1 ? 1 : hs),
        value: hs,
        link,
      },
    ];

    return row;
  });

  return (
    <div className={TileContainer()}>
      <GameGuideTileLg
        title={["home:guides.eft.statistics.title", "Statistics"]}
        description={[
          "home:guides.eft.statistics.description",
          "Dive into comprehensive weapon statistics from the Tarkov community on Blitz. Track kills per raid, accuracy, and damage done with each weapon.",
        ]}
        buttonText={["common:navigation.viewStatistics", "View Statistics"]}
        href={"/eft/stats/weapons"}
      >
        <DataTable
          borderless
          cols={columns}
          rows={rows}
          sortable={false}
          className={Table()}
          tallRows
        />
      </GameGuideTileLg>
    </div>
  );
}

const TileContainer = () => css`
  .card-contents {
    min-height: auto;
  }
`;

const Table = () => css`
  thead {
    height: var(--sp-10);
  }
`;
